import React from "react"

const Footer = () =>{
    return(
        <footer>
            Tipsene på denne siden er av <strong>informasjonsart</strong> og må <strong>ikke forveksles</strong> med finansiell eller økonomisk rådgivning.
        </footer>
    )
}

export default Footer